@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,400;0,600;1,400&display=swap');
body {
    font-family: 'Montserrat', sans-serif;
}
.loader-container {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    background: #fff;
    z-index: 100;
}
[x-cloak] {
    display: none !important;
}
.text-shadow-md {
    text-shadow: 0 4px 8px rgba(0,0,0,0.12), 0 2px 4px rgba(0,0,0,0.08);
}
#main_nav {
    position: fixed;
    top: 0;
    transition: transform 0.4s;
}
#main_nav.nav--hidden {
    transform:  translateY(-160px);
}